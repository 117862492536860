import React from "react";
import { Container, Spacer } from "./styles";
import useUi from "../../contexts/ui/useUi";
import Button from "../Button";
import useAuth from "../../contexts/auth/useAuth";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

interface PropTypes {
  handleRefresh?: () => void;
}

const Menu: React.FC<PropTypes> = ({ handleRefresh }) => {
  const { menu, strings, setMenu, loading, setLoading, setAlert } = useUi();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleRefreshDevides = async () => {
    try {
      setLoading(true);
      await api.get("refreshDevices");
      setAlert(strings.machinesUpdated);
      if (handleRefresh) handleRefresh();
    } catch {
      setAlert(strings.somethingWentWrong);
    } finally {
      setLoading(false);
      setMenu(false);
    }
  };

  return (
    <Container open={menu}>
      <Button
        label={strings.machines}
        onClick={() => {
          setMenu(false);
          navigate("/");
        }}
      />
      <Spacer />
      <Button
        label={strings.history}
        onClick={() => {
          setMenu(false);
          navigate("/history");
        }}
      />
      <Spacer />
      {user?.admin && (
        <div>
          <Button
            label={strings.generateReport}
            onClick={() => {
              setMenu(false);
              navigate("/report");
            }}
          />
          <Spacer />
          <Button
            label={strings.addUser}
            onClick={() => {
              setMenu(false);
              navigate("/createUser");
            }}
          />
          <Spacer />
        </div>
      )}
      <Button
        label={strings.disconnect}
        onClick={() => {
          setMenu(false);
          logout();
        }}
      />
    </Container>
  );
};

export default Menu;
