import styled from "styled-components";

interface PropTypes {
  fixed?: boolean;
}

export const Container = styled.div<PropTypes>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.section};
  align-items: center;
  padding: 16px;
  justify-content: space-between;

  ${({ fixed }) => fixed && `z-index:10; position: fixed; top:0px; left:0px`}
`;

export const AppName = styled.span`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.action};
  font-weight: 700;
  display: flex;
  cursor: text;
  text-transform: uppercase;
`;
