import React from "react";
import { AppName, Container } from "./styles";
import useUi from "../../contexts/ui/useUi";
import MenuButton from "../MenuButton";

interface PropTypes {
  fixed?: boolean;
}

const AppBar: React.FC<PropTypes> = ({ fixed }) => {
  const { strings, menu, setMenu } = useUi();
  return (
    <Container fixed={fixed}>
      <AppName>{strings.appName}</AppName>
      <MenuButton onClick={() => setMenu(!menu)} open={menu} />
    </Container>
  );
};

export default AppBar;
