import ptBr from "./pt-br";

const getStrings = (language: string): Strings => {
  switch (language) {
    default:
      return ptBr;
  }
};

export default getStrings;
