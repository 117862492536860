import styled from "styled-components";

export const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Input = styled.input`
  font-size: ${({ theme }) => theme.fonts.medium};
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: ${(props) => props.theme.colors.section};
  color: ${(props) => props.theme.colors.font};
  border: none;
  transition: all 0.2s;
  border-bottom: 0.3rem solid transparent;
  width: 30rem;
  display: block;

  margin-top: 8px;

  &:focus {
    outline: none;
  }
`;

export const Label = styled.label`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.action};
  font-weight: 700;
  cursor: text;
  margin-left: 0%;
`;
