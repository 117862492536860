import React, { useEffect, useMemo, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { AlertLabel, Container, Content, Header } from "./styles";

import AppBar from "../../components/AppBar";
import Menu from "../../components/Menu";
import Select from "../../components/Select";
import DateIput from "../../components/DateInput";
import CheckBox from "../../components/CheckBox";
import TextField from "../../components/textField";
import Button from "../../components/Button";
import Chip from "../../components/Chip";
import ReportDoc from "../../components/ReportDoc";

import {
  calculateDates,
  getFirstMinuteOfDay,
  getLastMinuteOfDay,
} from "../../utils/timeUtils";
import fortmatReportData from "../../utils/fortmatReportData";

import api from "../../services/api";

import AdminLayout from "../../layouts/adminLyt";

type SelectData = { label: string; value: number }[];

const periodOptions: SelectData = [
  { label: "Selecionar Período", value: 0 },
  { label: "Último mês", value: 1 },
  { label: "Último semeste", value: 2 },
  { label: "Último ano", value: 3 },
  { label: "Período Personalizado", value: 4 },
];

const Report: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [period, setPeriod] = useState(0);

  const [startDate, setStartDate] = useState<number>();
  const [endDate, setEndDate] = useState<number>();

  const [filterByUsers, setFilterByUsers] = useState<boolean>(false);
  const [filterByAppartment, setFilterByAppartment] = useState<boolean>(false);

  const [appartment, setAppartment] = useState("");
  const [appartmentsList, setAppartmentsList] = useState<string[]>([]);

  const [users, setUers] = useState<UserItem[]>([]);
  const [usersData, setUsersData] = useState<SelectData>([]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<UserItem[]>([]);

  const [report, setReport] = useState<ReportData[]>([]);

  const [isReportValid, setIsReportValid] = useState<boolean>(false);
  const [isDateValid, setIsDateValid] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
    setStartDate(0);
    setEndDate(0);
  }, []);

  useEffect(() => {

    setReport([]);

    if (period !== 0) {
      setIsReportValid(true);

      if (period === 4) {
        if (startDate) {
          if (endDate) {
            setIsDateValid(true);
          } else {
            setIsDateValid(false);
          }
        } else {
          setIsDateValid(false);
        }
      } else {
        setIsDateValid(true);
      }
    } else {
      setIsReportValid(false);
    }
  }, [period, startDate, endDate]);

  const getUsers = async () => {
    try {
      const { data } = await api.get<UserItem[]>("/getUsers");
      setUers(data);

      const aux: SelectData = [];

      aux.push({ label: `Selecionar Usuário`, value: 0 });

      data.forEach((user, index) =>
        aux.push({
          label: `${user.name} - ${user.appartment}`,
          value: index + 1,
        })
      );

      setUsersData(aux);
    } catch (err) {
      console.log(err);
    }
  };

  const renderDateSelectionInputs = () => {
    return (
      <div style={{ marginTop: 16 }}>
        <DateIput
          label="Data Inicial"
          onChange={(timeStamp) => setStartDate(getFirstMinuteOfDay(timeStamp))}
        />
        <DateIput
          label="Data Final"
          startDate={startDate}
          onChange={(timestamp) => setEndDate(getLastMinuteOfDay(timestamp))}
        />
      </div>
    );
  };

  const handleAddAppartment = () => {
    if (appartment.trim() === "") return;

    if (!appartmentsList.includes(appartment)) {
      setAppartmentsList([...appartmentsList, appartment]);
      setAppartment("");
    }
  };

  const handleRemoveAppartment = (id: string) => {
    const aux = appartmentsList.filter((ap) => ap !== id);
    setAppartmentsList(aux);
  };

  const handleAddUser = (index: number) => {
    if (!selectedUsers.includes(users[index])) {
      setSelectedUsers([...selectedUsers, users[index]]);
      setSelectedUser(0);
    }
  };

  const handleRemoveUser = (uid: string) => {
    const aux = selectedUsers.filter((user) => user.uid !== uid);
    setSelectedUsers(aux);
  };

  const handleGenerateRepport = async () => {
    if (startDate === undefined && endDate === undefined) {
      setIsReportValid(false);
      return;
    } else {
      setIsReportValid(true);
    }
    try {
      setLoading(true);
      if (period === 0) return;

      const formatedAppartments: number[] = [];
      if (filterByAppartment)
        appartmentsList.forEach((ap) => formatedAppartments.push(parseInt(ap)));

      const uidsList: string[] = [];
      if (filterByUsers)
        selectedUsers.forEach((user) => uidsList.push(user.uid));

      const dates =
        period === 4 ? { startDate, endDate } : calculateDates(period);

      const { data } = await api.post("/getReport", {
        users: uidsList,
        period: { start: dates.startDate, end: dates.endDate },
        appartments: formatedAppartments,
      });

      setReport(fortmatReportData(data));
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const Document = useMemo(() => <ReportDoc data={report} />, [report]);

  return (
    <AdminLayout>
      <Container>
        <AppBar />
        <Content>
          <Header>Gerar Relatório</Header>

          <div>
            <CheckBox
              checked={filterByUsers}
              label="Filtrar por usuários"
              onCheckChanged={() => setFilterByUsers(!filterByUsers)}
            />

            <CheckBox
              checked={filterByAppartment}
              label="Filtrar por apartamentos"
              onCheckChanged={() => setFilterByAppartment(!filterByAppartment)}
            />
          </div>

          <div style={{ marginTop: 16 }} />

          <Select data={periodOptions} onChange={setPeriod} value={period} />

          {period === 4 && renderDateSelectionInputs()}

          {filterByAppartment && (
            <div style={{ display: "flex", marginTop: 6 }}>
              <TextField
                id="appartmentNumber"
                type="number"
                value={appartment}
                onChange={setAppartment}
                placeholder="Número do Apartamento"
              />
              <Button label="+" onClick={handleAddAppartment} />
            </div>
          )}

          {filterByAppartment && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {appartmentsList.map((appartment, id) => (
                <Chip
                  key={id}
                  id={appartment}
                  label={appartment}
                  onRemove={handleRemoveAppartment}
                />
              ))}
            </div>
          )}

          {filterByUsers && (
            <div
              style={{
                display: "flex",
                marginTop: 6,
              }}
            >
              <Select
                data={usersData}
                onChange={setSelectedUser}
                value={selectedUser}
              />
              <Button
                label="+"
                onClick={() =>
                  selectedUser !== 0 && handleAddUser(selectedUser - 1)
                }
              />
            </div>
          )}

          {filterByUsers && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {selectedUsers.map((user, id) => (
                <Chip
                  key={id}
                  id={user.uid}
                  label={`${user.name} - ${user.appartment}`}
                  onRemove={handleRemoveUser}
                />
              ))}
            </div>
          )}

          <div style={{ marginTop: 16 }} />

          {!isReportValid ? (
            <AlertLabel>Selecione um tipo de período</AlertLabel>
          ) : (
            <>
              {!isDateValid ? (
                <AlertLabel>Selecione as datas</AlertLabel>
              ) : (
                <Button
                  label="Gerar Relatório"
                  onClick={handleGenerateRepport}
                  loading={loading}
                />
              )}
            </>
          )}

          <div style={{ marginTop: 16 }} />

          {report.length > 0 && (
            <PDFDownloadLink
              document={Document}
              fileName={"Relatório - Urbanit Lavanderia"}
            >
              <Button label="Baixar Relatório" />
            </PDFDownloadLink>
          )}
        </Content>
        <Menu />
      </Container>
    </AdminLayout>
  );
};

export default Report;
