const strings: Strings = {
  appName: "Urbanit - Lavanderia",
  login: "Entrar",
  email: "Email",
  password: "Senha",
  allocateMachine: "Ligar Máquina",
  underUsageBy: "Em uso por",
  disconnect: "Desconectar",
  generateReport: "Gerar Relatório",
  addUser: "Adicionar Usuário",
  machines: "Máquinas",
  appartmentNumber: "Número do apartamento",
  name: "Nome",
  add: "Adicionar",
  updatePassword: "Atualizar Senha",
  confirmPassword: "Confirmar Senha",
  confirm: "Confirmar",
  emailOrPasswordInvalids: "Email ou senha inválidos",
  passwordsMismatch: "Senhas não coicidem",
  somethingWentWrong: "Algo deu errado, tente nomamente mais tarde",
  allFieldsMustBeFilled: "Todos os campos precisam ser preenchidos",
  userAddeded: "Usuário Adicionado com sucesso",
  refreshMachines: "Atualizar Máquinas",
  machinesUpdated: "Máquinas atualizadas com sucesso",
  free: "Livre",
  cancel: "Cancelar",
  selectTime: "Selecionar Tempo",
  offline: "Offline",
  history: "Histórico",
  noHistory: "Sem Uso registrado para esse usuário", 
};

export default strings;
