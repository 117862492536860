import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`;

export const AlertLabel = styled.span`
font-size: ${({ theme }) => theme.fonts.larger};
color: ${(props) => props.theme.colors.action};
font-weight: 700;
display: flex;
cursor: text;
text-transform: uppercase;
`;

export const Header = styled.h1`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.font};
  display: flex;
  cursor: text;
  text-transform: capitalize;
  margin-bottom: 16px;
`;
