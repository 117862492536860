export default {
  title: "dark",

  colors: {
    background: "#353941",
    section: "#26282B",
    action: "#90B8F8",
    font: "#EEEEEE",
    error: "#DE5246",
  },

  fonts: {
    small: "1.2rem",
    medium: "1.8rem",
    larger: "2.1rem",
  },
};
