import React from "react";

import { CloseButton, Container, Message } from "./styles";

import useUi from "../../contexts/ui/useUi";

interface PropTypes {
  type: "error" | "success";
}

const Alert: React.FC<PropTypes> = ({ type }) => {
  const { alert, setAlert } = useUi();
  return (
    <Container type={type} open={alert !== null}>
      <Message>{alert}</Message>
      <CloseButton onClick={() => setAlert(null)}>&times;</CloseButton>
    </Container>
  );
};

export default Alert;
