export default (items: ReportItem[]): ReportData[] => {
  const reportData: { [key: string]: ReportData } = {};

  items.forEach((item) => {
    const date = new Date(item.timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const key = `${item.locator}-${year}-${month}`;

    if (!reportData[key]) {
      reportData[key] = {
        locator: item.locator,
        reports: [],
      };
    }

    const reportIndex = reportData[key].reports.findIndex(
      (report) => report.year === year && report.month === month
    );

    // Verificar se há outro item com o mesmo locator e machineId
    const hasDuplicateItem = item.machineId && reportData[key].reports.some(report =>
      report.items.some(existingItem =>
        existingItem.machineId === item.machineId &&
        Math.abs(existingItem.timestamp - item.timestamp) <= 15 * 60 * 1000 // 15 minutos em milissegundos
      )
    );

    if (!hasDuplicateItem) {
      if (reportIndex === -1) {
        reportData[key].reports.push({
          year,
          month,
          items: [item],
          totalCost: item.cost,
        });
      } else {
        reportData[key].reports[reportIndex].items.push(item);
        reportData[key].reports[reportIndex].totalCost += item.cost;
      }
    }
  });

  // Ordenar os itens dentro de cada grupo por timestamp (do mais recente para o mais antigo)
  for (const key in reportData) {
    reportData[key].reports.forEach((report) => {
      report.items.sort((a, b) => b.timestamp - a.timestamp);
    });

    reportData[key].reports.sort(
      (a, b) =>
        parseFloat(`${a.year}.${a.month}`) - parseFloat(`${b.year}.${b.month}`)
    );
  }

  return Object.values(reportData);
};
