import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  color: ${(props) => props.theme.colors.action};
  font-weight: 700;
  display: flex;
  cursor: text;
  text-transform: capitalize;
  margin-bottom: 32px;
`;
