import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";

import api from "../../services/api";

import useUi from "../ui/useUi";

type AuthContextData = {
  user: User | null;
  logout: () => void;
  login: (data: Login) => void;
  loadingUser: boolean;
  updatePassword: (data: UpdatePassword) => void;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const tokenKey = "@laumdryapp:token:";

  const { setAlert, strings, setLoading } = useUi();

  const [user, setUser] = useState<User | null>(null);
  const [loadingUser, setLoadingUSer] = useState(false);

  const validateToken = (token: string | null): boolean => {
    if (token === "null") return false;
    if (!token) return false;

    return true;
  };

  const updateToken = async (token: string | null) => {
    localStorage.setItem(
      `${tokenKey}authToken`,
      token === null ? JSON.stringify(token) : token
    );
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    if (token !== null) getUserData();
  };

  useEffect(() => {
    const loadStoragedData = async () => {
      setLoading(true);

      const token = localStorage.getItem(`${tokenKey}authToken`);
      if (validateToken(token)) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        getUserData();
      } else updateToken(null);

      setLoading(false);
    };

    loadStoragedData();
  }, []);

  const getUserData = async () => {
    try {
      setLoadingUSer(true);
      const { data } = await api.get("/getUserData");

      setUser(data);
    } catch {
      setAlert(strings.somethingWentWrong);
    } finally {
      setLoadingUSer(false);
    }
  };

  const login = async (data: Login) => {
    try {
      setLoading(true);
      const {
        data: { token },
      } = await api.post("/login", data);

      updateToken(token);
    } catch (error: any) {
      if (error.response.data.code === "auth/invalid-credential")
        setAlert(strings.emailOrPasswordInvalids);
      else setAlert(strings.somethingWentWrong);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    updateToken(null);
  };

  const updatePassword = async (data: UpdatePassword) => {
    if (!user) return;
    try {
      setLoading(true);

      await api.post("/updatePassword", data);

      setUser({ ...user, passwordOk: true });
    } catch (error) {
      setAlert(strings.somethingWentWrong);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loadingUser,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
