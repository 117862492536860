import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  cursor: pointer;
`;

interface BarProps {
  open?: boolean;
}

export const Bar1 = styled.div<BarProps>`
  width: 35px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.action};
  margin: 6px 0;
  transition: 0.4s;

  ${({ open }) => open && `transform: translate(0, 11px) rotate(-45deg);`}
`;

export const Bar2 = styled.div<BarProps>`
  width: 35px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.action};
  margin: 6px 0;
  transition: 0.4s;

  ${({ open }) => open && `opacity: 0;`}
`;

export const Bar3 = styled.div<BarProps>`
  width: 35px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.action};
  margin: 6px 0;
  transition: 0.4s;

  ${({ open }) => open && `transform: translate(0, -11px) rotate(45deg);`}
`;
