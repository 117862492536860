export default (reportItems: ReportItem[]): GroupedReports => {
  const groupedReports: GroupedReports = {};

  reportItems.forEach((item) => {
    const date = new Date(item.timestamp);
    const year = date.getFullYear();
    const month = date.getMonth(); // Obtendo o mês como um número de 0 a 11

    if (!groupedReports[year]) {
      groupedReports[year] = [];
    }

    const monthItem = groupedReports[year].find((item) => item.month === month);

    if (monthItem) {
      monthItem.items.push(item);
    } else {
      groupedReports[year].push({ month: month, items: [item], year });
    }
  });

  // Sorting items within each year
  for (const year in groupedReports) {
    groupedReports[year].sort((a, b) => {
      return b.month - a.month;
    });
  }

  for (const year in groupedReports) {
    for (const monthItem of groupedReports[year]) {
      monthItem.items.sort((a, b) => b.timestamp - a.timestamp); // Ordenando os itens dentro de cada mês em ordem decrescente de timestamp
    }
  }

  return groupedReports;
};
