import React, { PropsWithChildren } from "react";

import { AuthProvider } from "./contexts/auth";
import { UiProvider } from "./contexts/ui";

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <UiProvider>
      <AuthProvider>{children}</AuthProvider>
    </UiProvider>
  );
};

export default AppProvider;
