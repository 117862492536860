const enumScene = [
  { nameScene: "00:15h", time: 18, id: 'd6dcd550-d286-11ee-9672-325096b39f47' },
  { nameScene: "00:30h", time: 33, id: '7f413f38-d287-11ee-9e7a-325096b39f47' },
  { nameScene: "01:00h", time: 63, id: '81aff0de-d287-11ee-b93c-325096b39f47' },
  { nameScene: "01:30h", time: 93, id: '8392abf8-d287-11ee-be9a-325096b39f47' },
  { nameScene: "02:00h", time: 123, id: '85414cf2-d287-11ee-ab64-325096b39f47' },
  { nameScene: "02:30h", time: 153, id: '87401d4e-d287-11ee-9807-325096b39f47' },
  { nameScene: "03:00h", time: 183, id: '895413e2-d287-11ee-98dc-325096b39f47' },
];
export default enumScene;
