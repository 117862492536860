import styled, { keyframes } from "styled-components";

export const YearMonthIndicatorFixed = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.action};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 24px;

  background-color: ${({ theme }) => theme.colors.background};

  z-index: 10;
  position: fixed;
  top: 50px;
  left: 0px;
`;

export const YearMonthIndicator = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.action};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0px 24px;

  background-color: ${({ theme }) => theme.colors.background};
`;

export const YearMonthLabel = styled.span`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.action};
  font-weight: 700;
  display: flex;
  cursor: text;
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  margin-bottom: -100px;
`;

export const MonthContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 40px;
`;

const rotation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Loader = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid ${({ theme }) => theme.colors.font};
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 2.5s linear infinite;
`;

export const NameLabel = styled.span`
  font-size: ${({ theme }) => theme.fonts.medium};
  color: ${(props) => props.theme.colors.font};
  display: flex;
  cursor: text;
  text-transform: capitalize;
`;
