import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/authLyt";
import { Container, Content, Loader } from "./styles";
import MachineItem from "../../components/MachineItem";
import AppBar from "../../components/AppBar";
import Menu from "../../components/Menu";
import Alert from "../../components/Alert/indext";
import useUi from "../../contexts/ui/useUi";
import api from "../../services/api";
import useAuth from "../../contexts/auth/useAuth";

const Painel: React.FC = () => {
  const { alert, strings, setAlert } = useUi();
  const { user } = useAuth();

  const [machines, setMachines] = useState<Machine[]>([]);
  const [loadingMachines, setLoadingMachines] = useState(false);

  const hanldeAllocateMachine = async (scene: string, machine: Machine) => {
    if (machine.allocated) return;
    try {
      const scenes = await api.get(`/getScene?spaceId=${machine.space}`);

      const sceneSelected = scenes.data.find((s: any) => s.name === scene);

      await api.post("/allocateMachine", {
        machineId: machine.id,
        scene: sceneSelected,
      });

      getMachinesStatus();
    } catch {
      setAlert(strings.somethingWentWrong);
    }
  };

  const getMachinesStatus = async () => {
    try {
      setLoadingMachines(true);
      const { data } = await api.get<Machine[]>("/getMachines");
      const machineList = data;
      
      machineList?.sort((a: Machine, b: Machine) => (a.name < b.name) ? -1 : 1);

      setMachines(machineList);
    } catch {
      setAlert(strings.somethingWentWrong);
    } finally {
      setLoadingMachines(false);
    }
  };

  useEffect(() => {
    if (user) getMachinesStatus();
  }, [user]);

  return (
    <AuthLayout>
      <Container>
        <AppBar />
        {loadingMachines ? (
          <Content>
            <Loader />
          </Content>
        ) : (
          <Content>
            {machines.map((machine) => (
              <MachineItem
                key={machine.id}
                machine={machine}
                onClick={hanldeAllocateMachine}
                onEnd={getMachinesStatus}
              />
            ))}
          </Content>
        )}

        <Menu handleRefresh={getMachinesStatus} />
        <Alert
          type={alert === strings.somethingWentWrong ? "error" : "success"}
        />
      </Container>
    </AuthLayout>
  );
};

export default Painel;
