import React, { useEffect, useState } from "react";
import {
  AllocatedLabel,
  ButtonsContainer,
  Container,
  Content,
  MachineName,
  ProgressBar,
  TimeLeft,
} from "./styles";
import Button from "../Button";
import useUi from "../../contexts/ui/useUi";
import { intervalToDuration } from "date-fns";
import enumScene from "../../mock/scenes";
import { formatTimeLeft } from "../../utils/timeUtils";

interface PropTypes {
  onClick: (scene: string, machine: Machine) => void;
  machine: Machine;
  onEnd: () => void;
}

const MachineItem: React.FC<PropTypes> = ({ onClick, machine, onEnd }) => {
  const { strings, theme } = useUi();

  const [time, setTime] = useState("60:00");
  const [progress, setProgress] = useState(100);

  const [isTimeSelect, setIsTimeSelect] = useState(false);

  const handleTimer = async () => {
    if (!machine.allocated) {
      setProgress(100);
      return;
    }

    const end = new Date();
    const start = new Date(machine.timestamp);

    const duration = intervalToDuration({ start, end });

    const timeLeft = formatTimeLeft(
      machine.timestamp,
      end.getTime(),
      machine.service
    );

    if (timeLeft === "00:00:00" && machine.allocated) onEnd();

    setTime(timeLeft);

    if (duration.minutes && machine.service)
      if (duration.minutes === undefined) setProgress(0);
      else setProgress((duration.minutes! * 100) / machine.service);

    setTimeout(() => {
      if (timeLeft !== "00:00:00" && machine.allocated) handleTimer();
    }, 1000);
  };

  useEffect(() => {
    handleTimer();
  }, []);

  const getStatusText = (): string => {
    if (!machine.status) return strings.offline;

    if (!machine.allocated) return strings.free;

    return time;
  };

  const handleOpenTimeSelect = () => {
    if (!isTimeSelect && !machine.allocated && machine.status)
      setIsTimeSelect(true);
  };

  return (
    <Container
      cliclable={!isTimeSelect && !machine.allocated}
      onClick={handleOpenTimeSelect}
    >
      <MachineName>
        {machine.name}
        {isTimeSelect && ` - ${strings.selectTime}`}
      </MachineName>
      {!isTimeSelect ? (
        <Content>
          <ProgressBar progress={progress}>
            <TimeLeft>{getStatusText()}</TimeLeft>
          </ProgressBar>

          {machine.allocated ? (
            <AllocatedLabel>Em uso por: {machine.locator}</AllocatedLabel>
          ) : (
            <>
              {!machine.status ? (
                <AllocatedLabel>Equipamento sem Sinal</AllocatedLabel>
              ) : (
                <Button
                  label={strings.allocateMachine}
                  onClick={handleOpenTimeSelect}
                  textColor={theme.colors.action}
                />
              )}
            </>
          )}
        </Content>
      ) : (
        <Content>
          <ButtonsContainer>
            {enumScene.map((scene) => (
              <Button
                label={scene.nameScene}
                onClick={() => onClick(scene.nameScene, machine)}
                textColor={theme.colors.action}
                key={scene.time}
              />
            ))}
          </ButtonsContainer>
          <Button
            label={"cancelar"}
            onClick={() => setIsTimeSelect(false)}
            textColor={theme.colors.action}
          />
        </Content>
      )}
    </Container>
  );
};

export default MachineItem;
