import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

const rotation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Loader = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid ${({ theme }) => theme.colors.font};
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 2.5s linear infinite;
`;
