import React from "react";
import { Bar1, Bar2, Bar3, Container } from "./styles";

interface PropTypes {
  open?: boolean;
  onClick?: () => void;
}

const MenuButton: React.FC<PropTypes> = ({ open, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Bar1 open={open} />
      <Bar2 open={open} />
      <Bar3 open={open} />
    </Container>
  );
};

export default MenuButton;
