import styled from "styled-components";

interface MenuProps {
  open?: boolean;
}

export const Container = styled.nav<MenuProps>`
  position: fixed;
  top: 50px;
  right: -100vw;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.section};
  transition-duration: 0.5s;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ open }) => open && "transform: translateX(-100vw);"}

  z-index: 100;
`;

export const Spacer = styled.div`
  height: 16px;
`;
