import React, {
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
  SetStateAction,
  Dispatch,
} from "react";
import { DefaultTheme } from "styled-components";

import getStrings from "../../utils/strings";
import defaultTheme from "../../utils/theme";
import ptBr from "../../utils/strings/pt-br";

type TypeUiContext = {
  strings: Strings;
  theme: DefaultTheme;
  language: string;
  menu: boolean;
  setMenu: Dispatch<SetStateAction<boolean>>;
  alert: string | null;
  setAlert: Dispatch<SetStateAction<string | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const UiContext = createContext<TypeUiContext>({} as TypeUiContext);

export const UiProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const tokenKey = "@tccapp:token:";

  const [strings, setStrings] = useState<Strings>(ptBr);
  const [theme] = useState<DefaultTheme>(defaultTheme);
  const [language, setLanguage] = useState<string>("pt-br");
  const [menu, setMenu] = useState<boolean>(false);
  const [alert, setAlert] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLanguage(navigator.language);
  }, []);

  useEffect(() => {
    setStrings(getStrings(language));
  }, [language]);

  useEffect(() => {
    localStorage.setItem(`${tokenKey}theme`, theme.title);
  }, [theme]);

  return (
    <UiContext.Provider
      value={{
        strings,
        theme,
        language,
        menu,
        setMenu,
        alert,
        setAlert,
        loading,
        setLoading,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default UiContext;
