import React from "react";

import { Container, Label, Loader } from "./styles";

interface props {
  label: string;
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  href?: string;
  loading?: boolean;
}

const Button: React.FC<props> = ({
  label,
  onClick,
  backgroundColor,
  textColor,
  href,
  loading,
}) => {
  const handlePress = () => {
    if (!loading && onClick) onClick();
  };

  return (
    <Container
      href={href}
      backgroundColor={backgroundColor}
      onClick={handlePress}
    >
      <Label disabled={loading} textColor={textColor}>
        {label}
      </Label>
      <Loader disabled={loading} />
    </Container>
  );
};

export default Button;
