import React, { useState } from "react";

import useUi from "../../contexts/ui/useUi";

import { Container, Title } from "./styles";
import TextField from "../../components/textField";
import Button from "../../components/Button";
import useAuth from "../../contexts/auth/useAuth";
import UpdatePasswordLyt from "../../layouts/updatePasswordLyt";
import Alert from "../../components/Alert/indext";

const UpdatePassword: React.FC = () => {
  const { strings, theme, loading, setAlert } = useUi();
  const { updatePassword } = useAuth();

  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    if (password.trim() !== "" && confirmPassword.trim() !== "")
      updatePassword({ password, confirmPassword });
    else setAlert(strings.passwordsMismatch);
  };

  return (
    <UpdatePasswordLyt>
      <Container>
        <Title>{strings.updatePassword}</Title>
        <TextField
          id='password'
          label='Senha'
          type='password'
          value={password}
          onChange={setPassword}
          placeholder='Senha'
          required
        />
        <TextField
          id='confirmPassword'
          label={strings.confirmPassword}
          type='password'
          value={confirmPassword}
          onChange={setConfirmPassword}
          placeholder={strings.confirmPassword}
          required
        />
        <Button
          label={strings.confirm}
          backgroundColor={theme.colors.action}
          onClick={handleSubmit}
          textColor={theme.colors.font}
          loading={loading}
        />
      </Container>
      <Alert type='error' />
    </UpdatePasswordLyt>
  );
};

export default UpdatePassword;
