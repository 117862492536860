import React, { useState } from "react";
import UnAuthLayout from "../../layouts/unAuthLyt";

import useUi from "../../contexts/ui/useUi";

import { Container, Title } from "./styles";
import TextField from "../../components/textField";
import Button from "../../components/Button";
import useAuth from "../../contexts/auth/useAuth";
import Alert from "../../components/Alert/indext";

const Login: React.FC = () => {
  const { strings, theme, setAlert, loading } = useUi();
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    setAlert(null);
    if (email.trim() !== "" && password.trim() !== "")
      login({ email, password });
    else setAlert(strings.allFieldsMustBeFilled);
  };

  return (
    <UnAuthLayout>
      <Container>
        <Title>{strings.login}</Title>
        <TextField
          id='email'
          label={strings.email}
          type='email'
          value={email}
          onChange={setEmail}
          placeholder={strings.email}
          required
        />
        <div style={{ height: 26 }} />
        <TextField
          id='password'
          label='Senha'
          type='password'
          value={password}
          onChange={setPassword}
          placeholder='Senha'
          required
        />
        <div style={{ height: 16 }} />
        <Button
          label={strings.login}
          backgroundColor={theme.colors.action}
          onClick={handleSubmit}
          textColor={theme.colors.font}
          loading={loading}
        />
      </Container>
      <Alert type='error' />
    </UnAuthLayout>
  );
};

export default Login;
