import React from "react";
import styled from "styled-components";

interface PropTypes {
  checked: boolean;
  onCheckChanged: () => void;
  label: string;
}

const Container = styled.div`
  &:not(:first-child) {
    margin-top: 8px;
  }

  display: flex;
  align-items: center;

  width: 40rem;
`;

const Label = styled.label`
  margin-left: 16px;

  font-size: ${({ theme }) => theme.fonts.medium};
  color: ${(props) => props.theme.colors.font};
  display: flex;
  cursor: text;
  text-transform: capitalize;
`;

const Input = styled.input`
  width: 18px;
  height: 18px;
`;

const CheckBox: React.FC<PropTypes> = ({ checked, onCheckChanged, label }) => {
  return (
    <Container>
      <Input type='checkbox' checked={checked} onChange={onCheckChanged} />
      <Label>{label}</Label>
    </Container>
  );
};

export default CheckBox;
