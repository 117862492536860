import styled from "styled-components";

interface ContainerProps {
  cliclable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 88%;
  background-color: ${({ theme }) => theme.colors.section};
  border-radius: 6px;
  padding: 8px 16px;
  margin-bottom: 16px;
  max-width: 500px;

  ${({ cliclable }) => cliclable && "cursor:pointer;"}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const MachineName = styled.span`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.action};
  font-weight: 700;
  margin-bottom: 16px;
  display: flex;
  cursor: text;
`;

interface ProgressBar {
  progress: number;
}

export const ProgressBar = styled.div<ProgressBar>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(
      closest-side,
      ${({ theme }) => theme.colors.section} 79%,
      transparent 80% 100%
    ),
    conic-gradient(
      ${({ theme }) => theme.colors.action} ${({ progress }) => progress}%,
      ${({ theme }) => theme.colors.font} 0
    );

  transition: all 0.2s;
`;

interface TimeLeftProps {
  offline?: boolean;
}

export const TimeLeft = styled.span<TimeLeftProps>`
  font-size: ${({ theme }) => theme.fonts.small};
  color: ${({ theme, offline }) =>
    offline ? theme.colors.error : theme.colors.action};
  font-weight: 700;
  display: flex;
  cursor: text;
`;

export const AllocatedLabel = styled.span`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.font};
  font-weight: 400;
  display: flex;
  cursor: text;
  margin-top: 8px;
`;
