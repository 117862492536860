import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { format } from "date-fns";
import { formatDuration } from "../../utils/timeUtils";

interface PropTypes {
  data: ReportData[];
}

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const ReportDoc: React.FC<PropTypes> = ({ data }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.title}>Relatório - Urbanit Lavanderia</Text>
        {data.map((item, id) => (
          <View key={id} style={styles.locatorView}>
            <Text style={styles.locatorName}>{item.locator}</Text>
            <View>
              {item.reports.map((report) => (
                <View>
                  <Text style={styles.monthYear}>
                    {months[report.month - 1]} / {report.year}
                  </Text>
                  <View style={styles.divider} />
                  {report.items.map((item) => (
                    <View style={styles.itemContainer}>
                      <Text style={{ ...styles.label, textAlign: "left" }}>
                        {format(new Date(item.timestamp), "dd/MM/yyyy hh:mm aaa")}
                      </Text>
                      <Text style={styles.label}>
                        {formatDuration(item.duration)}
                      </Text>
                      {item.machineName && (
                        <Text style={styles.label}>{item.machineName}</Text>
                      )}
                      <Text style={{ ...styles.label, textAlign: "right" }}>
                        {formatarModeaReal(item.cost)}
                      </Text>
                    </View>
                  ))}
                  <View style={styles.totalContainer}>
                    <Text>Total {months[report.month - 1]}: </Text>
                    <Text>{formatarModeaReal(report.totalCost)}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

const formatarModeaReal = (data: any) => {
  data = Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(data);

  return data;
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
  },
  title: {
    fontSize: 24,
    color: "black",
    alignSelf: "center",
    marginBottom: 36,
    marginTop: 16,
  },
  locatorName: {
    fontSize: 24,
    color: "black",
    alignSelf: "flex-start",
    marginBottom: 16,
    textTransform: "capitalize",
  },
  monthYear: {
    fontSize: 24,
    color: "black",
    alignSelf: "flex-end",
    marginBottom: 8,
    textTransform: "capitalize",
  },
  divider: {
    height: 2,
    width: "100%",
    backgroundColor: "black",
    marginBottom: 16,
  },
  itemContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginBottom: 16,
  },
  totalContainer: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  locatorView: {
    marginBottom: 32,
    borderBottomWidth: 2,
    borderColor: "black",
    padding: 16,
  },
  label: {
    flex: 1,
    textAlign: "center",
  },
});

export default ReportDoc;
