import React, { PropsWithChildren } from "react";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

import useAuth from "../contexts/auth/useAuth";
import useUi from "../contexts/ui/useUi";
import { Container } from "./styles";

const AuthLayout: React.FC<PropsWithChildren<DefaultProps>> = ({
  children,
}) => {
  const { user, logout } = useAuth();

  const { strings } = useUi();

  const location = useLocation();
  const navigate = useNavigate();

  const handleNav = (path: string) => {
    if (location.pathname !== path) navigate(path);
  };

  if (!user) return <Navigate to='/login' replace />;

  if (!user.passwordOk) return <Navigate to='/updatePassword' replace />;

  return <Container>{children}</Container>;
};

export default AuthLayout;
