import React, { useEffect, useMemo, useState } from "react";

import AppBar from "../../components/AppBar";
import Menu from "../../components/Menu";

import sortByMonth from "../../utils/sortByMonth";
import {
  YearMonthIndicator,
  YearMonthLabel,
  Container,
  Content,
  ListContainer,
  ItemContainer,
  MonthContainer,
  Loader,
  NameLabel,
  YearMonthIndicatorFixed,
} from "./styles";
import { formatCost, formatDuration } from "../../utils/timeUtils";
import api from "../../services/api";
import Alert from "../../components/Alert/indext";
import useUi from "../../contexts/ui/useUi";
import AuthLayout from "../../layouts/authLyt";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const History: React.FC = () => {
  const { setAlert, strings } = useUi();

  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<GroupedReports>([]);
  const [yearsList, setYsearsList] = useState<number[]>([]);
  const [currentYear, setCurrentYear] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState<number>(0);
  const [noHistory, setNoHistory] = useState<boolean>(false);

  const [reportItems, setReportItems] = useState<ReportItem[]>([]);

  const getReports = async () => {
    try {
      setLoading(true);

      const { data: items } = await api.get<ReportItem[]>("/getHistory");

      if (items.length === 0) {
        setNoHistory(true);

        return;
      }
      setNoHistory(false);

      const filteredList = sortByMonth(items);
      let years: number[] = [];

      Object.keys(filteredList).forEach((key) => years.push(parseInt(key)));

      years = years.sort((a, b) => b - a);

      setList(filteredList);
      setYsearsList(years);
      setReportItems(items);

      setCurrentMonth(filteredList[years[0]][0].month);
      setCurrentYear(years[0]);
    } catch (errr) {
      setAlert(strings.somethingWentWrong);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      let year = currentYear;
      let month = currentMonth;

      sections.forEach((section: HTMLElement) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < 100 && rect.bottom - 55 > 100) {
          const id = section.id;
          if (id.includes("year")) {
            year = parseInt(id.split("-")[1]);
          } else if (id.includes("month")) {
            month = parseInt(id.split("-")[1]);
          }
        }
      });

      setCurrentYear(year);
      setCurrentMonth(month);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentMonth, currentYear]);

  const renderEntry = (item: ReportItem) => (
    <ItemContainer>
      <YearMonthLabel>
        {String(new Date(item.timestamp).getDate()).padStart(2, "0")}
      </YearMonthLabel>
      <NameLabel>{item.locator}</NameLabel>
      <NameLabel>&#8986; {formatDuration(item.duration)}</NameLabel>
      <NameLabel>&#128176; {formatCost(item.cost)}</NameLabel>
    </ItemContainer>
  );

  const renderEmpty = () => (
    <ItemContainer>
      <NameLabel>Sem Uso registrado para esse usuario</NameLabel>
    </ItemContainer>
  );

  const renderMonth = (month: Month) => (
    <MonthContainer id={`month-${month.month}`}>
      <YearMonthIndicator>
        <YearMonthLabel>{months[month.month]}</YearMonthLabel>
        <YearMonthLabel>{month.year}</YearMonthLabel>
      </YearMonthIndicator>
      {month.items.map((item) => renderEntry(item))}
    </MonthContainer>
  );

  const renderList = useMemo(
    () =>
      yearsList.map((year) => (
        <ListContainer id={`year-${year.toString()}`}>
          {list[year].map((month) => renderMonth(month))}
        </ListContainer>
      )),
    [yearsList, list]
  );

  const renderIndicator = () => (
    <YearMonthIndicatorFixed>
      <YearMonthLabel>{months[currentMonth]}</YearMonthLabel>
      <YearMonthLabel>{currentYear}</YearMonthLabel>
    </YearMonthIndicatorFixed>
  );

  return (
    <AuthLayout>
      <Container>
        <AppBar fixed />
        {reportItems.length > 0 && renderIndicator()}
        <Content>
          {loading && <Loader />}
          {noHistory ? (
            <>
              <div style={{ height: 100 }} />
              <YearMonthLabel>{strings.noHistory}</YearMonthLabel>
            </>
          ) : (
            renderList
          )}
        </Content>
        <Menu />
        <Alert type="error" />
      </Container>
    </AuthLayout>
  );
};

export default History;
