import React, { useState } from "react";

import api from "../../services/api";
import AdminLayout from "../../layouts/adminLyt";
import { Container, Content, Title } from "./styles";

import AppBar from "../../components/AppBar";
import Menu from "../../components/Menu";
import TextField from "../../components/textField";
import Button from "../../components/Button";

import useUi from "../../contexts/ui/useUi";
import Alert from "../../components/Alert/indext";

const CreateUser: React.FC = () => {
  const { strings, theme, setAlert, setLoading, loading } = useUi();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [appartmentNumber, setAppartmentNumber] = useState("");
  const [error, setError] = useState(false);

  const handleCreateUser = async () => {
    try {
      setLoading(true);
      setError(false);
      if (
        email.trim() !== "" &&
        name.trim() !== "" &&
        appartmentNumber.trim() !== ""
      ) {
        await api.post("/addUser", { email, name, appartmentNumber });

        setEmail("");
        setName("");
        setAppartmentNumber("");

        setAlert(strings.userAddeded);
      } else {
        setAlert(strings.allFieldsMustBeFilled);
        setError(true);
      }
    } catch {
      setAlert(strings.somethingWentWrong);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <Container>
        <AppBar />
        <Content>
          <Title>{strings.addUser}</Title>
          <TextField
            id='email'
            label={strings.email}
            type='email'
            value={email}
            onChange={setEmail}
            placeholder={strings.email}
            required
          />
          <div style={{ height: 26 }} />
          <TextField
            id='nome'
            label={strings.name}
            type='text'
            value={name}
            onChange={setName}
            placeholder={strings.name}
            required
          />
          <div style={{ height: 26 }} />
          <TextField
            id='numeroApto'
            label={strings.appartmentNumber}
            type='number'
            value={appartmentNumber}
            onChange={setAppartmentNumber}
            placeholder={strings.appartmentNumber}
            required
          />
          <div style={{ height: 16 }} />
          <Button
            label={strings.add}
            backgroundColor={theme.colors.action}
            textColor={theme.colors.font}
            onClick={handleCreateUser}
            loading={loading}
          />
          <Menu />
          <Alert type={error ? "error" : "success"} />
        </Content>
      </Container>
    </AdminLayout>
  );
};

export default CreateUser;
