import styled from "styled-components";

interface AlertProps {
  type: "error" | "success";
  open?: boolean;
}

export const Container = styled.div<AlertProps>`
  padding: 6px 16px;

  background-color: ${({ type, theme }) =>
    type === "error" ? theme.colors.error : theme.colors.action};

  color: ${({ theme }) => theme.colors.font};

  width: 80vw;

  position: absolute;
  bottom: 16px;
  left: 16px;
  border-radius: 6px;

  transition: 0.3s;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  z-index: -1000;

  ${({ open }) => open && "z-index:1000;"}
`;

export const CloseButton = styled.span`
  margin-left: 15px;
  color: ${({ theme }) => theme.colors.font};
  font-weight: bold;
  float: right;
  font-size: ${({ theme }) => theme.fonts.larger};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.background};
  }
`;

export const Message = styled.span`
  font-size: ${({ theme }) => theme.fonts.larger};
  color: ${(props) => props.theme.colors.font};
  font-weight: 400;
  display: flex;
  cursor: text;
  flex: 1;
`;
