export const formatTimeLeft = (
  start: number,
  atual: number,
  total: number
): string => {
  const duracaoMillis = total * 60 * 1000; // Converter minutos para milissegundos
  const tempoDecorrido = atual - start;
  const tempoRestante = duracaoMillis - tempoDecorrido;

  if (tempoRestante <= 0) {
    return "00:00:00"; // Se o tempo restante for negativo ou zero, retorna "00:00:00"
  } else {
    // Calcular horas, minutos e segundos
    const horas = Math.floor(tempoRestante / (1000 * 60 * 60));
    const minutos = Math.floor(
      (tempoRestante % (1000 * 60 * 60)) / (1000 * 60)
    );
    const segundos = Math.floor((tempoRestante % (1000 * 60)) / 1000);

    // Formatando para hh:mm:ss
    const horasFormatadas = String(horas).padStart(2, "0");
    const minutosFormatados = String(minutos).padStart(2, "0");
    const segundosFormatados = String(segundos).padStart(2, "0");

    return `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
  }
};

export const formatDuration = (duration: number): string => {
  // Calcula as horas e minutos
  const hours = Math.floor(duration / 3600);
  const minutes = (duration % 3600) / 60;

  // Formata as horas e minutos para terem sempre 2 dígitos
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Retorna a string formatada
  return `${formattedHours}:${formattedMinutes}H`;
};

export const formatCost = (cost: number): string => {
  // Retorna a string formatada
  return `R$ ${cost.toString().replace(".", ",").slice(0, 4)}`;
};

export const formatDate = (timeStamp: string): string => {
  const date = new Date(timeStamp);

  const day = String(date.getDate()).padStart(2, "0");

  const hours = String(date.getHours());
  const min = String(date.getMinutes());

  return `${day}`;
};

export function getLastMinuteOfDay(timestamp: number): number {
  const data = new Date(timestamp);
  data.setHours(23, 59, 59, 999);
  return data.getTime();
}

export function getFirstMinuteOfDay(timestamp: number): number {
  const data = new Date(timestamp);
  data.setHours(0, 0, 0, 0);
  return data.getTime();
}

export function calculateDates(option: number): { startDate: number, endDate: number } {
  const currentDate = new Date();
  let startDate = new Date();

  switch (option) {
      case 1:
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
          break;
      case 2:
          startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1);
          break;
      case 3:
          startDate = new Date(currentDate.getFullYear() - 1, 0, 1);
          break;
      default:
          throw new Error('Invalid option. Please provide 1, 2, or 3.');
  }

  // Set start date to the beginning of the day
  startDate.setHours(0, 0, 0, 0);

  // Set end date to the current date
  const endDate = currentDate.getTime();

  return { startDate: startDate.getTime(), endDate };
}