import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.action};
  margin-bottom: 3rem;
`;
