import styled, { keyframes } from "styled-components";

type ContainerProps = {
  backgroundColor?: string;
  textColor?: string;
  disabled?: boolean;
};

const rotation = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Container = styled.a<ContainerProps>`
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  background-color: ${(props) => props.backgroundColor || "transparent"};
  padding: 1.5rem 4rem;

  border-radius: 6px;
  transition: all 0.2s;
  animation: 1.4s ease-out;
  backface-visibility: hidden;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: ${(props) => props.backgroundColor || "transparent"};
    transition: all 0.4s;
  }
`;

export const Label = styled.span<ContainerProps>`
  font-size: ${(props) => props.theme.fonts.medium};
  color: ${({ theme, textColor }) => textColor || theme.colors.action};
  ${({ disabled }) => disabled && "filter: brightness(85%);"}
  text-transform: uppercase;
  text-decoration: none;
  text-decoration: none;
`;

export const Loader = styled.span<ContainerProps>`
  width: 20px;
  height: 20px;
  border: 3px solid ${({ theme }) => theme.colors.font};
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: ${rotation} 2.5s linear infinite;

  position: absolute;
  transform: translateX(-50%);

  ${({ disabled }) =>
    disabled ? "visibility: visible;" : "visibility: hidden;"}
`;
