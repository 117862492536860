import React, { PropsWithChildren } from "react";

import { Navigate } from "react-router-dom";
import useAuth from "../contexts/auth/useAuth";
import { Container } from "./styles";

const UpdatePasswordLyt: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  if (!user) return <Navigate to='/login' replace />;

  if (user.passwordOk) return <Navigate to='/' replace />;

  return <Container>{children}</Container>;
};

export default UpdatePasswordLyt;
