import React, { useMemo } from "react";
import { format, add } from "date-fns";

import { Container, Input, Label } from "./styles";

interface PropTypes {
  onChange: (timestamp: number) => void;
  startDate?: number;
  label: string;
}

const DateIput: React.FC<PropTypes> = ({ onChange, startDate, label }) => {
  const min = useMemo(
    () =>
      startDate ? format(new Date(startDate), "yyyy-MM-dd") : "2024-01-01",
    [startDate]
  );

  return (
    <Container>
      <Label>{label}</Label>
      <Input
        min={min}
        max={format(new Date(), "yyyy-MM-dd")}
        type='date'
        onChange={({ target: { value } }) => {
          onChange(add(new Date(value), { days: 1 }).getTime());
        }}
      />
    </Container>
  );
};

export default DateIput;
