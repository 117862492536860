import React from "react";
import styled from "styled-components";

interface PropTypes {
  label: string;
  id: string;
  onRemove: (id: string) => void;
}

const Container = styled.div`
  padding: 3px 6px;
  background-color: ${({ theme }) => theme.colors.section};
  border-radius: 12;

  &:not(:last-child) {
    margin-right: 8px;
  }
  display: flex;

  margin-top: 6px;
`;

const Label = styled.p`
  font-size: ${({ theme }) => theme.fonts.medium};
  color: ${(props) => props.theme.colors.font};
  cursor: text;
  text-transform: capitalize;
  margin-right: 8px;
`;

const Delete = styled.p`
  font-size: ${({ theme }) => theme.fonts.medium};
  color: ${(props) => props.theme.colors.font};
  cursor: text;
  text-transform: capitalize;
  cursor: pointer;
`;

const Chip: React.FC<PropTypes> = ({ label, onRemove, id }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Delete onClick={() => onRemove(id)}>&#x2715;</Delete>
    </Container>
  );
};

export default Chip;
